<template v-if="links.length > 0">
  <nav class="text-center my-10">
    <button
      class="button mx-1 bg-white"
      :class="{
        'button --primary-inverse': link.active,
        'text-gray-400 bg-white': link.url === null,
      }"
      v-for="(link, key) in links"
      :key="key"
      :disabled="link.url === null"
      @click="handleClick(link.url)"
    >
      <fa-icon v-if="key === 0" icon="angle-left"></fa-icon>
      <fa-icon
        v-else-if="key === links.length - 1"
        icon="angle-right"
      ></fa-icon>
      <span v-else> {{ link.label }} </span>
    </button>
  </nav>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(link) {
      this.$emit("clicked", link);
    },
  },
  name: "PaginationLinks",
};
</script>